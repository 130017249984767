export const environment = {
  production: false,
  webSocketApi: 'https://dev.v2.api.mercadomeet.com/socket.io/socket.io.js',
  api: 'https://dev.v2.api.mercadomeet.com/api/v2',
  mercadoWeb: 'https://dev.mercadomeet.com.co',
  webGame: 'https://dev.meetcoin.cash',
  appName: 'MERCADOMEET',
  maxNumberCanSelected: 9,
  initLives: 0,
  initTries: 0,
  maxTries: 10,
  maxVideoSizeMB: 50,
  wompiKeys: {
    baseURL: 'https://sandbox.wompi.co/v1',
    publicKey: 'pub_test_wq0LA9hw1bzqLYDHUwAt6afPxraxzcaD',
  },
  wompiBundle: 'https://checkout.wompi.co/widget.js',
  modoBundle: 'https://ecommerce-modal.preprod.modo.com.ar/bundle.js'
};
